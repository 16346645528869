
import { defineComponent, computed } from 'vue';
import { useStore } from 'vuex';

export default defineComponent({
    emits: ['init-value'],
    setup(props, { emit }) {
        const { state } = useStore();
        const buList = computed(() =>
            state.user.buList.filter((bu: any) => {
                return bu.parentId === null;
            })
        );
        const dogetDefault = () => {
            const mbIndex = buList.value.findIndex((i: any) => {
                return i.nameEn === 'MBPC';
            });
            const initIndex = mbIndex === -1 ? 0 : mbIndex;
            // eslint-disable-next-line vue/custom-event-name-casing
            emit('init-value', buList.value[initIndex].nameEn);
        };
        dogetDefault();
        return {
            buList,
        };
    },
});
